'use strict'

import BANNER_PNG from '../assets/banner.png'

export const SurveyBanner = {
  extend: 'Flex',
  props: {
    background: `url(${BANNER_PNG}) no-repeat`,
    backgroundSize: 'cover',
    aspectRatio: '1149 / 432',
    border: '1px, solid',
    '@dark': {
      borderColor: 'gray4'
    },
    '@light': {
      backgroundColor: 'white',
      borderColor: 'gray10'
    }
  }
}
