'use strict'

import { Grid } from '@symbo.ls/ui'

export const CalBooking = {
  extend: Grid,
  tag: 'form',

  props: {
    columnGap: 'C',
    padding: 'D C2',
    rowGap: 'C2'
  },

  on: {
    init: () => {
      (function (C, A, L) {
        const p = function (a, ar) {
          a.q.push(ar)
        }
        const d = C.document
        C.Cal = C.Cal || function () {
          const cal = C.Cal
          const ar = arguments
          if (!cal.loaded) {
            cal.ns = {}
            cal.q = cal.q || []
            d.head.appendChild(d.createElement('script')).src = A
            cal.loaded = true
          }
          if (ar[0] === L) {
            const api = function () {
              p(api, arguments)
            }
            const namespace = ar[1]
            api.q = api.q || []
            typeof namespace === 'string'
              ? (cal.ns[namespace] = api) && p(api, ar)
              : p(cal, ar)
            return
          }
          p(cal, ar)
        }
      })(window, 'https://cal.com/embed.js', 'init')
    },
    render: (el, s) => {
      window.Cal('inline', {
        elementOrSelector: el.node, // You can also provide an element directly
        calLink: 'team/symbols', // The link that you want to embed. It would open https://cal.com/jane in embed
        config: {
          name: s.fullname, // Prefill Name
          email: s.email, // Prefill Email
          notes: 'Symbols Demo', // Prefill Notes
          // guests: ['janedoe@gmail.com', 'test@gmail.com'], // Prefill Guests
          theme: 'dark' // "dark" or "light" theme
        }
      })
    }
  }
}
