'use strict'

import { SurveySection } from '.'

export const ThankYou = {
  extend: SurveySection,

  props: {
    padding: 'C2'
  },

  H2: {
    fontWeight: '300',
    text: 'Thank you',
    lineHeight: 1
  },

  Grid: {
    props: {
      margin: 'B 0 D',
      gap: 'B 7%',
      columns: 'repeat(2, 1fr)',
      childProps: { margin: '0' }
    },
    childExtend: 'P',
    ...[{
      text: 'To help us better understand your business and customise our product demonstration to your specific needs, we kindly request you to complete the following survey.'
    }]
  }
}
