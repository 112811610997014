'use strict'

import { Flex } from 'smbls'

export const App = {
  extend: Flex,

  state: {
    companyName: 'Peacox Studio',
    globalTheme: 'dark'
  },

  props: {
    theme: 'document',
    minHeight: '100dvh',
    boxSizing: 'border-box',
    boxSize: '100dvh 100%',
    position: 'absolute',
    overflow: 'hidden auto',

    flow: 'column',
    align: 'center space-between',

    content: {
      flex: 1
    }
  },

  HeaderOfGuest: {
    props: {
      position: 'sticky',
      top: '0'
    },

    Logo: {
      props: { href: '/' }
    },

    links: null,
    ContinueButton: null
  }
}
