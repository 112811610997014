'use strict'

export default {
  'SurveyInput.companyName': {
    title: { text: 'Company name' }
  },

  'SurveyInput.fullname': {
    title: { text: 'Your name' }
  },

  'SurveyInput.email': {
    type: 'email',
    title: { text: 'Your work email address' }
  },

  'SurveySelect.position': () => ({
    title: { text: 'Your position {{ ../companyName }}' },
    options: [
      { text: 'Please specify', props: { selected: true, disabled: true } },
      { text: 'CEO / Managing Director' },
      { text: 'CTO' },
      { text: 'CMO' },
      { text: 'CFO' },
      { text: 'CIO' },
      { text: 'Web Development Manager' },
      { text: 'Marketing Manager' },
      { text: 'Other (please specify)', attr: { value: 'Other' } }
    ]
  }),

  Gutter: { size: 'B2' },

  'SurveySelect.developersAmount': {
    title: { text: 'How many web developers are currently part of {{ ../companyName }}?' },
    options: [
      { text: 'Please specify', props: { selected: true, disabled: true } },
      { text: '1' },
      { text: '2-5' },
      { text: '6-10' },
      { text: 'More than 10 (please specify)', attr: { value: 'Other' } }
    ]
  },

  'SurveySelect.websitesPerMonth': {
    title: { text: 'How many new websites does {{ ../companyName }} typically develop in a month, on average?' },
    options: [
      { text: 'Please specify', props: { selected: true, disabled: true } },
      { text: '1' },
      { text: '2-4' },
      { text: '5-8' },
      { text: '9-11' },
      { text: '12 or more (please specify)', attr: { value: 'Other' } }
    ]
  },

  'SurveySelect.projectsDuration': {
    title: { text: 'What is the average duration of a typical web development project for {{ ../companyName }}?' },
    options: [
      { text: 'Please specify', props: { selected: true, disabled: true } },
      { text: 'Less than 50 hours' },
      { text: '50-100 hours' },
      { text: '100-150 hours' },
      { text: '150-200 hours' },
      { text: '200-250 hours' },
      { text: 'More than 250 hours (please specify)', attr: { value: 'Other' } }
    ]
  },

  'Gutter.2': { size: 'B2' },

  'SurveyIconicOptions.framework': {
    title: { text: 'Which framework does {{ ../companyName }} use?' },

    options: {
      DOMQL: { icon: 'domql outline' },
      JQuery: { icon: 'jquery outline' },
      React: { icon: 'react outline' },
      Vue: { icon: 'vue outline' },
      Angular: { icon: 'angular outline' },
      Svelte: { icon: 'svelte outline' },
      Other: { text: 'Other' }
    }
  },

  'SurveyIconicOptions.frontendLanguage': {
    title: { text: 'Which frontend languages does {{ ../companyName }} use?' },

    options: {
      HTML: { icon: 'html outline' },
      JavaScript: { icon: 'js outline' },
      TypeScript: { icon: 'ts outline' },
      ELM: { icon: 'elm outline' },
      Other: { text: 'Other' }
    }
  },

  'SurveyIconicOptions.designTool': {
    title: { text: 'Which of the design tool does {{ ../companyName }} use?' },

    options: {
      Sketch: { icon: 'sketch outline' },
      Figma: { icon: 'figma outline' },
      XD: { icon: 'xd outline' },
      Other: { text: 'Other' }
    }
  },

  'Gutter.3': { size: 'B2' },

  'SurveyButtonOptions.usedNocodeTools': {
    multiple: true,

    title: { text: 'Which of the no-code tools does {{ ../companyName }} use?' },

    options: {
      Airtable: { text: 'Airtable' },
      Wix: { text: 'Wix' },
      Webflow: { text: 'Webflow' },
      Tilda: { text: 'Tilda' },
      Squarespace: { text: 'Squarespace' },
      GoogleForms: { text: 'Google Forms' },
      Bubble: { text: 'Bubble' },
      Typeform: { text: 'Typeform' },
      Other: {}
    }
  },

  'SurveyButtonOptions.usedDesignSystem': {
    title: { text: 'Which of the Design System / UI framework does {{ ../companyName }} use?' },

    options: {
      Tailwind: { text: 'Tailwind' },
      Bootstrap: { text: 'Bootstrap' },
      Ant: { text: 'Ant.design' },
      Chakra: { text: 'Chakra UI' },
      Mantine: { text: 'Mantine' },
      Carbon: { text: 'Carbon' },
      Material: { text: 'Material' },
      Other: {}
    }
  },

  'SurveyButtonOptions.usedAnalytics': {
    title: { text: 'Which of the analytics tools does {{ ../companyName }} use?' },

    options: {
      Mixpanel: { text: 'Mixpanel' },
      Hotjar: { text: 'Hotjar' },
      Heap: { text: 'Heap' },
      GoogleAnalytics: { text: 'Google Analytics' },
      Fullstory: { text: 'Fullstory' },
      Other: {}
    }
  },

  'SurveyButtonOptions.industriesServed': {
    title: { text: 'Which industries for web development does {{ ../companyName }} primarily serve?' },

    options: {
      Agnostic: { text: 'All (industry agnostic)' },
      ECommerce: { text: 'E-commerce' },
      Healthcare: { text: 'Healthcare' },
      Finance: { text: 'Finance' },
      Education: { text: 'Education' },
      Entertainment: { text: 'Entertainment' },
      Technology: { text: 'Technology' },
      Other: {}
    }
  },

  'Gutter.4': { size: 'B2' },

  'SurveySelect.growthGoals': {
    title: { text: 'What are {{ ../companyName }} future growth plans or expansion goals?' },
    options: [
      { text: 'Please specify', props: { selected: true, disabled: true } },
      { text: 'Increasing the number of clients' },
      { text: 'Expanding services into new markets' },
      { text: 'Hiring additional web developers' },
      { text: 'Offering specialised web development solutions' },
      { text: 'More than 10 (please specify)', attr: { value: 'Other' } }
    ]
  },

  'SurveySelect.saasSatisfaction': {
    title: { text: 'How would you rate {{ ../companyName }} current level of satisfaction with your existing web development tools and systems?' },
    options: [
      { text: 'Please specify', props: { selected: true, disabled: true } },
      { text: 'Highly satisfied' },
      { text: 'Somewhat satisfied' },
      { text: 'Neutral' },
      { text: 'Somewhat dissatisfied' },
      { text: 'Highly dissatisfied' }
    ]
  },

  'Gutter.5': { size: 'B2' },

  'SurveyButtonOptions.areasForDevelopment': {
    title: { text: 'Which of the following areas are most crucial for {{ ../companyName }} success in web development?' },

    options: {
      FrontEnd: { text: 'Front-end development' },
      BackEnd: { text: 'Back-end development' },
      UIUX: { text: 'UI/UX design' },
      Performance: { text: 'Performance optimization' },
      SquareSpace: { text: 'Security measures' },
      Reponsive: { text: 'Mobile responsiveness' },
      SEO: { text: 'Search engine optimization (SEO)' },
      Other: {}
    }
  },

  'SurveyButtonOptions.rankPainPoints': {
    title: { text: 'What key pain point(s) does {{ ../companyName }} have when it comes to web development?' },

    options: {
      FrontEnd: { text: 'Front-end development' },
      BackEnd: { text: 'Back-end development' },
      UIUX: { text: 'UI/UX design' },
      Performance: { text: 'Performance optimization' },
      SquareSpace: { text: 'Security measures' },
      Reponsive: { text: 'Mobile responsiveness' },
      SEO: { text: 'Search engine optimization (SEO)' },
      Other: {}
    }
  }
}
