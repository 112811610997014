'use strict'

export * from './Banner'
export * from './SurveyForm'
export * from './CalBooking'
export * from './ThankYou'
export * from './Footer'

export const SurveySection = {
  props: {
    padding: 'A C2'
  }
}
