'use strict'

export const SurveyMain = {
  extend: 'Flex',

  state: {
    // companyName: 'Peacox Studio'
  },

  props: {
    flow: 'column',
    width: '100%',
    maxWidth: '120em',
    marign: '0 auto',
    padding: 'D E1'
  },

  Section: {
    props: {},

    H2: {
      fontWeight: '300',
      text: 'Symbols survey',
      lineHeight: 1
    },

    P: {
      props: {
        margin: 'Z1 0'
      },
      text: 'Thanks for your interest in ',
      Strong: { text: 'Symbols.' }
    },

    H5: {
      margin: 'C 0 0',
      text: 'Help us understand your business'
    },

    Grid: {
      props: {
        margin: 'B 0 D',
        gap: 'B 7%',
        columns: 'repeat(2, 1fr)',
        childProps: { margin: '0' }
      },
      childExtend: 'P',
      ...[{
        text: 'To help us better understand your business and customise our product demonstration to your specific needs, we kindly request you to complete the following survey.'
      }, {
        //   props: { gridColumnStart: '1' },
        //   text: 'To help us better understand your business and customise our product demonstration to your specific needs, we kindly request you to complete the following survey.'
        // }, {
        text: 'Your responses will guide us in understanding (company name) unique challenges and goals, enabling us to present how Symbols can address them effectively.'
      }]
    }
  },

  SurveyBanner: {
    props: {
      margin: 'A 0'
    },

    Box: {
      props: {
        alignSelf: 'flex-end',
        padding: 'C2 D'
      },

      H1: {
        maxWidth: 'E2',
        text: 'Symbols of Single Source',
        lineHeight: 1.3
      },

      P: {
        props: {
          maxWidth: 'G3+A',
          margin: 'Z1 0'
        },
        text: 'An interactive, centralized platform to build products as easily as filling Typeform and Airtable, also as powerful as Bubble, Figma, etc.'
      }
    }
  },

  Section_prep: {
    props: {},

    H5: {
      margin: 'C 0 0',
      text: 'How to book a demo?'
    },

    Grid: {
      props: {
        margin: 'B 0 A',
        gap: 'B 7%',
        columns: 'repeat(2, 1fr)',
        childProps: { margin: '0' }
      },
      childExtend: 'P',
      ...[{
        text: 'Please complete the following survey in order to proceed onto the next page, where you will choose an available slot.'
      }]
    }
  },

  SurveyForm: {},

  CalBooking: {},

  ThankYou: {}
}

export default {
  '/': SurveyMain
}
