'use strict'

import { Flex } from 'smbls'

export const SurveyFooter = {
  extend: Flex,

  props: {
    theme: 'navbar',
    position: 'sticky',
    zIndex: '2',
    width: '100%',
    bottom: '0',
    fontSize: 'Z',
    padding: 'A2 B',
    margin: '0 -B'
  },

  Steps: {}
}
