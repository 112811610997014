'use strict'

import { Grid } from '@symbo.ls/ui'
import FIELDS from './FIELDS'

export const SurveyForm = {
  extend: Grid,
  tag: 'form',

  state: {},

  props: {
    columnGap: 'C',
    padding: 'D C2',
    rowGap: 'C2'
  },

  ...FIELDS,

  on: {
    stateUpdate: (changes, element, state) => {
      console.log(state)
    }
  },

  Code: {
    props: {},
    title: {},
    code: {
      props: { padding: 'A A1' },
      text: (el, s) => JSON.stringify(s.parse(), null, 2)
    },
    buttons: null
    // buttons: {
    //   framework: null
    // }
  },

  SurveyFooter: {}
}
